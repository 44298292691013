<template>
  <div class="app-wrapper">
    <nav-bar />
    <v-main class="grey lighten-4">
      <v-container>
        <transition name="slide-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import NavBar from "@/components/user/NavBar.vue";
export default {
  components: {
    NavBar,
  },

  created() {
    let customer = JSON.parse(localStorage.getItem('loggedInCustomer'))
    if(customer) this.$store.commit('SET_LOGGED_IN_USER', customer)
    else this.$router.push('/login')
  },
};
</script>

<style></style>
