<template>
  <div>
    <v-app-bar app color="white">
      <v-container>
        <div class="d-flex flex-row">
          <v-img
            src="~@/assets/logo.svg"
            min-height="50"
            max-width="125"
            contain
          ></v-img>

          <v-spacer></v-spacer>
          <div class="d-flex flex-row">
            <v-list-item>
              <v-btn elevation="0" plain class="text-capitalize font-weight-bold secondary--text" :to="'/login'">Mes trajets</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn elevation="0" plain class="text-capitalize font-weight-bold secondary--text" :to="'/home/profile/'">Profile</v-btn>
            <v-list-item class="ml-5">
              <v-btn elevation="0" class="text-capitalize" color="secondary" @click="logout">Logout</v-btn>
            </v-list-item></v-list-item>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),

  methods: {
    logout() {
      this.$store.dispatch('logoutFromFirebase').then(() => {
        this.$router.replace("/login");
      });
    },
  }
}
</script>

<style scoped>
.sidebar-text {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
}
</style>
